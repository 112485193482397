import { useState } from 'react';
import { Field, Values, Errors, FieldValue, defaultField } from './data';

interface Options {
  fields: Partial<Field>[];
  initialValues: Values;
  initialErrors: Errors;
  initialGlobalErrors: string[];
  onSubmit: (form: Form) => void;
  validate: GlobalValidate;
}

type GlobalValidate = (form: Form) => string[];

let defaultOptions: Options = {
  fields: [],
  initialValues: {},
  initialErrors: {},
  initialGlobalErrors: [],
  validate: (form: Form) => [],
  onSubmit: (form: Form) => {},
};

interface FormState {
  values: Values;
  setValues: (values: Values) => void;
  errors: Errors;
  setErrors: (errors: Errors) => void;
  globalErrors: string[];
  setGlobalErrors: (errors: string[]) => void;
  section: number;
  setSection: (section: number) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

class FormField {
  field: Field;
  state: FormState;

  constructor(field: Field, state: FormState) {
    this.field = field;
    this.state = state;
  }

  render() {
    let fieldName = this.field.name;
    return this.field.widget({
      field: this.field,
      value: this.state.values[fieldName] || '',
      errors: this.state.errors[fieldName] || [],
      onChange: this.onChange,
    });
  }

  onChange = (value: FieldValue) => {
    this.state.setValues({ ...this.state.values, [this.field.name]: value });
  };
}

export class Form {
  fields: Field[];
  state: FormState;
  globalValidate: GlobalValidate;
  maxSection: number;
  onSubmitFinal: (form: Form) => void;

  constructor(
    fields: Field[],
    state: FormState,
    globalValidate: GlobalValidate,
    onSubmit: (form: Form) => void
  ) {
    this.fields = fields;
    this.state = state;
    this.globalValidate = globalValidate;
    this.maxSection = fields.reduce((s, f) => Math.max(f.section, s), 0);
    this.onSubmitFinal = onSubmit;
  }

  validate = () => {
    let isValid = true;
    let errors: Errors = {};
    let minSectionError = this.maxSection;
    let curSection = this.state.section;
    let isLastSection = this.maxSection === curSection;

    this.fields.forEach((f: Field) => {
      let value = this.state.values[f.name] || '';
      if (!isLastSection && f.section !== curSection) {
        return;
      }

      if (f.isRequired && !value.trim()) {
        errors[f.name] = [`${f.label} is required.`];
        minSectionError = Math.min(minSectionError, f.section);
        return;
      }

      if (f.validate) {
        let e = f.validate(value);
        if (e.length > 0) isValid = false;
        errors[f.name] = e;
      }
    });

    this.state.setErrors(errors);

    let globalErrors = this.globalValidate(this);
    this.state.setGlobalErrors(globalErrors);
    this.state.setSection(minSectionError);

    if (globalErrors.length > 0) isValid = false;

    return isValid;
  };

  onSubmit = async (e: any) => {
    if (!this.validate()) return false;
    let curSection = this.state.section;
    let isLastSection = this.maxSection === curSection;
    if (!isLastSection) {
      this.state.setSection(curSection + 1);
    } else {
      this.onSubmitFinal(this);
    }
    return false;
  };

  elems = () => {
    return this.fields
      .filter((f) => f.section === this.state.section)
      .map((f) => new FormField(f, this.state));
  };

  prevSection = (e: any) => {
    let section = Math.max(0, this.state.section - 1);
    this.state.setSection(section);
  };

  isLastSection = () => {
    return this.state.section === this.maxSection;
  };
}

export default function useForm(options: Partial<Options>) {
  let {
    fields,
    initialValues,
    initialErrors,
    initialGlobalErrors,
    onSubmit,
    validate,
  } = Object.assign({}, defaultOptions, options);
  let realFields = fields.map((f) => ({ ...defaultField, ...f }));
  let [values, setValues] = useState<Values>(initialValues);
  let [errors, setErrors] = useState<Errors>(initialErrors);
  let [globalErrors, setGlobalErrors] = useState<string[]>(initialGlobalErrors);
  let [section, setSection] = useState<number>(0);
  let [loading, setLoading] = useState<boolean>(false);

  let state: FormState = {
    values,
    errors,
    setValues,
    setErrors,
    globalErrors,
    setGlobalErrors,
    section,
    setSection,
    loading,
    setLoading,
  };

  return new Form(realFields, state, validate, onSubmit);
}
